<section id="managedMagicBands" class="{{userDevice}}">
    <div class="managedBandsHeader">
        <h2 [translate]="'descriptions.magicbandspage.sections.managed_magicbands_title'"></h2>
        <p [innerHTML]="'descriptions.magicbandspage.sections.managed_magicbands_subtitle' | translate"></p>
    </div>
    <section *ngFor="let section of managedBandSections()" class="buildABandManagedSection {{section}}">
        <div *ngIf="section === 'passes'">
            <h2 [translate]="'descriptions.magicbandspage.sections.guest_passes_title'"></h2>
            <p [innerHTML]="'descriptions.magicbandspage.sections.guest_passes_subtitle' | translate"></p>
        </div>
        <div
            *ngFor="let id of managedBandSectionIds(section)"
            id="guest_{{id}}"
            class="managedMagicBandList guestManagedBands"
        >
            <label
                href="javascript:void(null)"
                class="guestNameAndAvatarLabel spanFitElement tappable touchable touchEffectBackground"
                (click)="toggleBandCollection(id, $event)"
            >
                <div [ngClass]="{
                    'expandedArrow': true,
                    'mdxFont': true,
                    'opened': activeGuest === id
                }">
                    <span class="icon icon--pep" aria-hidden="true">
                        <span class="di di-norgie-closed"></span>
                    </span>
                </div>
                <div class="guestAvatar">
                    <img src="{{data.guests[id].avatarUrl}}" />
                    <div class="mediaThumb">
                        <div class="mediaContainer">
                            <img
                                src="{{FormatThumbImageURL(managedBands[section][id][0])}}"
                                class="{{managedBands[section][id][0].mediaType}}"
                            />
                        </div>
                    </div>
                </div>
                <div class="guestDataContainer">
                    <profile-guest-data
                        class="guestData"
                        [guestID]="id"
                        [data]="data"
                        [managedBands]="true"
                        [isGuestPass]="section === 'passes'"
                    ></profile-guest-data>
                    <div
                        class="mediaCount"
                        [innerHTML]="( managedBands[section][id].length >= 2 ? 'descriptions.magicbandspage.sections.band_count_plural' : 'descriptions.magicbandspage.sections.band_count_singular' ) | translate: {count: managedBands[section][id].length}"
                    ></div>
                </div>
            </label>
            <div
                class="managedBandsCollection"
                attr.data-guest-id="{{id}}"
                *ngIf="activeGuest === id"
                [@openClose]
            >
                <div *ngFor="let band of managedBands[section][id]" class="media">
                    <div class="magicBandWrapper">
                        <div class="mediaImage {{band.mediaType}}">
                            <img
                                src="{{FormatImageURL(band)}}"
                            />
                        </div>
                        <div *ngIf="userDevice === 'mobile'" class="mediaInfo">
                            <div class="bandStatusLabel">{{formatTitleCase(band.state)}}</div>
                            <div class="bandSerialNumber">{{formatExternalNumber(band.externalId)}}</div>
                        </div>
                        <div class="bandStatusContainer">
                            <div *ngIf="userDevice === 'desktop'" class="mediaInfo">
                                <div class="bandStatusLabel">{{formatTitleCase(band.state)}}</div>
                                <div class="bandSerialNumber">{{formatExternalNumber(band.externalId)}}</div>
                            </div>
                            <div class="button-wrapper">
                                <div class="btn-group">
                                    <wdpr-button
                                        (click)="statusButton(band, STATE_ACTIVATE)"
                                        class="btn btn-primary first {{getDisabledState(band.secondaryState)}}"
                                        [translate]="(band.secondaryState === SUBSTATUS_KEEPSAKE || band.secondaryState === SUBSTATUS_GUEST_LOST_OR_STOLEN ? CONTENT_DESCRIPTION_MAGIC_BANDS + '.cta_activate' : CONTENT_DESCRIPTION_MAGIC_BANDS + '.cta_deactivate')"
                                    >
                                    </wdpr-button>
                                    <wdpr-button
                                        (click)="statusButton(band, STATE_LOST)"
                                        class="btn btn-primary"
                                        [translate]="(band.secondaryState === SUBSTATUS_GUEST_LOST_OR_STOLEN ? CONTENT_DESCRIPTION_MAGIC_BANDS + '.cta_report_found' : CONTENT_DESCRIPTION_MAGIC_BANDS + '.cta_report_lost')"
                                    >
                                    </wdpr-button>
                                </div>
                                <div
                                    class="bandDeactivationExplaination"
                                    [innerHTML]="'descriptions.magicbandspage.sections.deactivation_explanation' | translate"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="horizontalSeparator fullWidth"></div>
    </section>
</section>
