<section class="magicBandOrders {{siteId}}" *ngIf="orderSets.orders.length">
    <header class="magicBandIndexSectionHeader">
        <div [innerHTML]="'descriptions.magicbandspage.sections.magicband_order_subtitle' | translate"></div>
        <div class="missing_a_guest" [innerHTML]="'descriptions.magicbandspage.sections.missing_a_guest' | translate"></div>
    </header>
    <div *ngFor="let order of orderSets.orders" id="requestID-{{order.requestId}}" class="magicBandOrder">
        <profile-vignette
            [vignette]="order.vignette"
            [shipCode]="order.shipCode"
        ></profile-vignette>
        <div class="magicBandOrderDetails">
            <h3
                *ngIf="siteId===siteIdConstant.WDW"
                class="mediaCount"
                [translateParams]="{count: order.count}"
                [translate]="( order.count > 1 ? 'descriptions.magicbandspage.sections.order_count_plural' : 'descriptions.magicbandspage.sections.order_count_singular' )"
            ></h3>
            <h3
                *ngIf="siteId===siteIdConstant.DCL"
                class="stateRoom"
                [translateParams]="{stateroom: order.stateroom}"
                [translate]="'descriptions.magicbandspage.sections.stateroom_singular'"
            ></h3>
            <div class="buildABandNotificationInline">
                <p
                    *ngIf="siteId===siteIdConstant.WDW"
                    class="pleaseNote"
                    [innerHTML]="'descriptions.magicbandspage.sections.please_note' | translate: {guest_count: order.sortedGuests.length}"
                ></p>
                <p>
                    <strong
                        *ngIf="siteId===siteIdConstant.DCL"
                        [innerHTML]="( order.guestCount > 1 ? 'descriptions.magicbandspage.sections.order_count_plural' : 'descriptions.magicbandspage.sections.order_count_singular' ) | translate: {count: order.guestCount}"
                    ></strong>
                </p>
                <div [innerHTML]="'descriptions.magicbandspage.sections.please_note_copy' | translate"></div>
                <div *ngIf="siteId===siteIdConstant.DCL">
                    <a
                        class="colorActiveBrightBlue underlined"
                        (click)="scrollTo((hasManagedBands() ? 'managedMagicBands' : 'findMagicBand'))"
                        id="managedMagicBandsAnchor"
                        [translate]="'descriptions.magicbandspage.sections.managedband_anchor'"
                    ></a>
                </div>
            </div>
            <div class="guestList">
                <div
                    *ngFor="let id of order.sortedGuests"
                    id="guest-{{id}}"
                    class="guestSelection"
                >
                    <profile-guest-data
                        [guestID]="id"
                        [data]="data"
                        [managedBands]="false"
                        [siteID]="siteId"
                        [productCode]="order.productCode(id)"
                        [confirmedCustomization]="order.confirmedCustomization(id)"
                        [bandData]="order.bandData(id)"
                    ></profile-guest-data>
                </div>
            </div>
            <div
                *ngIf="order.status === fulfillmentStatus"
                class="orderDisclaimer"
            >
                <div [translate]="'descriptions.magicbandspage.sections.shippingSoon'"></div>
            </div>
            <div
                *ngIf="order.isOrderProcessing"
                class="orderDisclaimer"
                [innerHTML]="( order.count > 1 ? 'descriptions.magicbandspage.sections.order_disclaimer_plural' : 'descriptions.magicbandspage.sections.order_disclaimer_singular' ) | translate"
            ></div>
            <div
                *ngIf="!showHidePurchaseWindowNotice(order) && order.isLoggedInGuestAbleToShipOrderToAddress()"
                class="orderCTAs"
            >
                <wdpr-button
                    *ngIf="order.isInCustomizationStatus && order.daysUntilCountdownDeadline > 0"
                    (click)="clickGetStartedButtonHandler(order.getPrimaryGuestAddress, order.requestId)"
                    theme="primary"
                    size="standard" 
                    role="button"
                    aria-disabled="false" 
                    [translate]="CONTENT_DESCRIPTION_MAGIC_BANDS + '.cta_get_started'"
                ></wdpr-button>
                <div
                    *ngIf="order.isInCustomizationStatus && order.daysUntilCountdownDeadline > 0 && siteId===siteIdConstant.DCL"
                    class="ccil-link"
                    [innerHTML]="('descriptions.magicbandspage.sections.castaway_club_link') | translate: {res_number: order.reservationNumber}"
                >
                </div>
                <div
                    *ngIf="!order.isInCustomizationStatus && order.daysUntilCountdownDeadline < 1 && siteId===siteIdConstant.DCL"
                    [translate]="'descriptions.magicbandspage.sections.ship_to_home'"
                ></div>
                <div
                    *ngIf="siteId===siteIdConstant.WDW && order.isInCustomizationStatus && order.daysUntilCountdownDeadline < 1"
                    [translate]="'descriptions.magicbandspage.sections.bands_not_selected'"
                ></div>
                <wdpr-button *ngIf="!order.isInCustomizationStatus && order.daysUntilCountdownDeadline < 1 && siteId===siteIdConstant.WDW"
                    (click)="navigateToOrderHistory()"
                    theme="primary"
                    size="standard" 
                    role="button"
                    aria-disabled="false" 
                    [translate]="CONTENT_DESCRIPTION_MAGIC_BANDS + '.cta_order_history'"
                ></wdpr-button>
            </div>
            <div class="shippingInformation" *ngIf="order.shippingLabel">
                <div>
                    <span
                        [translateParams]="{guest: formatTitleCase(order.shippingLabel.recipientName)}"
                        [translate]="'descriptions.magicbandspage.sections.shipped'"
                    ></span>
                    <span
                        [translateParams]="{carrier: order.shippingLabel.carrier}"
                        [translate]="'descriptions.magicbandspage.sections.shippedCarrier'"
                    ></span>
                </div>
                <div>
                    <span
                        [translate]="'descriptions.magicbandspage.sections.shippedArrival'"
                    ></span>
                    <span>&nbsp;{{order.shippingLabel.arrivalRangeString}}</span>
                    <span
                        [innerHTML]="('descriptions.magicbandspage.sections.shippedTracking') | translate: {tracking_number: order.shippingLabel.trackingNumber, tracking_link: order.shippingLabel.carrierLink}"
                    ></span>
                </div>
            </div>
            <div
                class="orderDisclaimer"
                *ngIf="!order.isLoggedInGuestAbleToShipOrderToAddress()"
                [translate]="'descriptions.magicbandspage.sections.locale_not_allowed'"
            ></div>
            <profile-countdown
                *ngIf="showHidePurchaseWindowNotice(order)"
                [numberOfDays]="order.daysUntilCanPurchase"
                type="purchase"
            ></profile-countdown>
                

            <div class="horizontalSeparator"></div>
            <div class="orderSource">
                <div class="entitlement">
                    <profile-reservation-data
                        [order]="order"
                    ></profile-reservation-data>
                </div>
                <profile-countdown
                    *ngIf="!showHidePurchaseWindowNotice(order) && showHideCustomizeCountDown(order)"
                    [numberOfDays]="order.daysUntilCountdownDeadline"
                    type="customize"
                ></profile-countdown>
            </div>
        </div>
    </div>
</section>
